var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "categories-mission",
      attrs: { column: "", "justify-center": "", "fill-height": "" }
    },
    [
      _c(
        "transition",
        {
          attrs: { name: "categories-instructions-transition", mode: "out-in" }
        },
        [
          _vm.isExplain
            ? _c(
                "v-flex",
                {
                  key: "instructions",
                  staticClass: "mission-instructions",
                  attrs: { "d-flex": "" }
                },
                [
                  _c("ResizableText", {
                    attrs: { message: _vm.getCurrentMission.instructions }
                  })
                ],
                1
              )
            : _c(
                "v-flex",
                {
                  key: "categories",
                  staticClass: "categories",
                  attrs: { "d-flex": "", "align-center": "" }
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "align-center": "",
                        "justify-center": ""
                      }
                    },
                    _vm._l(_vm.categoryAnswers, function(cat, i) {
                      return _c(
                        "v-flex",
                        {
                          key: i,
                          staticClass: "cat-card-wrap",
                          attrs: { "ma-1": "" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "cat-card",
                              class: { "cat-active": _vm.isAnswerPlayed(cat) },
                              attrs: { flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showAnswer(cat)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "number", attrs: { dark: "" } },
                                [_vm._v(_vm._s(i + 1))]
                              ),
                              _c(
                                "div",
                                { staticClass: "answer", attrs: { dark: "" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "truncated tr2"
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(_vm._s(cat))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [_c("span", [_vm._v(_vm._s(cat) + " ")])]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  ref: "truncated" + i,
                                  refInFor: true,
                                  staticClass: "truncated hidden",
                                  class: "truncated" + i
                                },
                                [_vm._v(" " + _vm._s(cat) + " ")]
                              ),
                              _c(
                                "div",
                                {
                                  ref: "full" + i,
                                  refInFor: true,
                                  staticClass: "full hidden"
                                },
                                [_vm._v(_vm._s(cat))]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm.isHost
        ? _c("v-flex", { attrs: { shrink: "" } }, [
            _vm._v(" Answers: " + _vm._s(_vm.correctAnswer) + " ")
          ])
        : _vm._e(),
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _c("TextField", {
            staticClass: "mission-text-feild",
            attrs: {
              placeholder: _vm.placeholder,
              status: _vm.status,
              show: _vm.showTextField,
              buttonText: "SUBMIT"
            },
            on: { onSubmit: _vm.submit }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }