import { render, staticRenderFns } from "./Categories.vue?vue&type=template&id=4174a4f4&"
import script from "./Categories.vue?vue&type=script&lang=js&"
export * from "./Categories.vue?vue&type=script&lang=js&"
import style0 from "./Categories.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VFlex,VLayout,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/Users/finnkelly/Code/Go Remote/remote-chat-finn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4174a4f4')) {
      api.createRecord('4174a4f4', component.options)
    } else {
      api.reload('4174a4f4', component.options)
    }
    module.hot.accept("./Categories.vue?vue&type=template&id=4174a4f4&", function () {
      api.rerender('4174a4f4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Games/Categories.vue"
export default component.exports