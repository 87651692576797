<template>
  <v-layout column justify-center fill-height class="categories-mission">
    <!-- Mission question -->
    <transition name="categories-instructions-transition" mode="out-in">
      <v-flex
        :key="'instructions'"
        v-if="isExplain"
        d-flex
        class="mission-instructions"
      >
        <ResizableText :message="getCurrentMission.instructions" />
      </v-flex>

      <v-flex :key="'categories'" d-flex align-center v-else class="categories">
        <v-layout row wrap align-center justify-center>
          <v-flex
            class="cat-card-wrap"
            ma-1
            v-for="(cat, i) in categoryAnswers"
            :key="i"
          >
            <v-card
              @click="showAnswer(cat)"
              flat
              class="cat-card"
              :class="{ 'cat-active': isAnswerPlayed(cat) }"
            >
              <div dark class="number">{{ i + 1 }}</div>
              <div dark class="answer">
                <!-- <div class="truncated tr1" v-if="!truncatedArray[i]">
                  {{ cat }}
                </div> -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div class="truncated tr2" v-on="on">{{ cat }}</div>
                  </template>
                  <span>{{ cat }} </span>
                </v-tooltip>
              </div>
              <div
                class="truncated hidden"
                :class="'truncated' + i"
                :ref="'truncated' + i"
              >
                {{ cat }}
              </div>
              <div class="full hidden" :ref="'full' + i">{{ cat }}</div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </transition>
    <v-flex shrink v-if="isHost"> Answers: {{ correctAnswer }} </v-flex>
    <v-flex shrink>
      <TextField
        class="mission-text-feild"
        :placeholder="placeholder"
        :status="status"
        :show="showTextField"
        :buttonText="'SUBMIT'"
        @onSubmit="submit"
      />
    </v-flex>
  </v-layout>
</template>

<script>
// categoryAnswers is form GameMixin
import { mapGetters, mapState } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "./ResizableText.vue"
import TextField from "./TextField.vue"
const MISSION_DATA_TYPE_ERROR_MESSAGE = "Got unsupported mission asnwer type"
const serialize = string => String(string).toLowerCase().trim()
export default {
  name: "Categories",
  mixins: [GameMixin],
  components: {
    ResizableText,
    TextField
  },
  props: {
    mode: String
  },
  data() {
    return {
      waitingText: "Waiting on Scribe...",
      truncatedArray: []
    }
  },
  computed: {
    ...mapState("group", ["currentGlobalTeam"]),
    ...mapGetters("auth", ["isHost", "isAudit"]),
    ...mapGetters([
      "missionSuccess",
      "missionCompleted",
      "missionAnswers",
      "getCurrentMission",
      "missionAllAnswers",
      "missionPlaysArray",
      "nOfIncorrectMissionAnswers",
      "missionCorrectAnswer",
      "isScribe"
    ]),
    categoryAnswers() {
      if (!Array.isArray(this.missionCorrectAnswer)) {
        console.error(MISSION_DATA_TYPE_ERROR_MESSAGE)
        return []
      } else {
        return this.missionCorrectAnswer
      }
    },
    teamID() {
      return this.isHost || this.isAudit
        ? this.currentGlobalTeam
        : this.$store.getters.teamID
    },
    correctAnswer() {
      return this.getCurrentMission.answer
    },
    status() {
      if (this.canPlay) {
        return "active" + this.lineThroughClass
      } else {
        return ""
      }
    },
    lineThroughClass() {
      return this.lastPlayIncorrect && this.missionAnswersLength
        ? " line-through"
        : ""
    },
    showTextField() {
      return this.isExplain ? "" : "show"
    },
    optionsClasses() {
      return {
        show: this.mode === "play"
      }
    },
    isExplain() {
      return this.mode === "explain"
    },
    canPlay() {
      return this.isScribe && !this.isExplain
    },
    missionAnswersLength() {
      return this.missionAnswers.length
    },
    latestAnswer() {
      return this.missionAnswers[this.missionAnswersLength - 1]
    },
    placeholder() {
      if (this.isHost) {
        return this.waitingText
      } else if (this.canPlay) {
        return "Enter here..."
      } else {
        return ""
      }
    }
  },
  methods: {
    showAnswer(answer) {
      if (this.isHost) {
        this.answer = answer
        this.checkAnswer()
      }
    },
    isGiven(string) {
      return this.missionAnswers.indexOf(string) > -1
    },
    isAnswerPlayed(answer) {
      var missionAnswers = this.missionAllAnswers
      // console.log("TEAMID ", this.teamID)
      // console.log("MISSION ANSWERS BEFORE", missionAnswers)
      if (this.teamMission) {
        missionAnswers = this.missionPlaysArray
        missionAnswers = missionAnswers.filter(
          item => item.teamID == this.teamID
        )
        var newArr = []
        for (var i in missionAnswers) {
          if (missionAnswers[i] && Array.isArray(missionAnswers[i].answer))
            newArr.push(missionAnswers[i].answer[0])
        }
        missionAnswers = newArr
      }
      // console.log("MISSION ANSWERS", missionAnswers)
      return this.doesContainString(missionAnswers, answer)
      // return this.doesContainString(this.missionAllAnswers, answer)
    },
    doesContainString(array, str) {
      if (typeof str !== "string") return false
      if (!Array.isArray(array)) return false
      return array.some(string => serialize(string) === serialize(str))
    },
    async submit(data) {
      if (!this.isScribe || this.isCompleted)
        return console.log("No rights for submit")
      if (!data) return console.log("Won't submit an empty string")
      this.answer = data
      await this.checkAnswer()
      this.answer = ""
    }
  }
}
</script>

<style lang="scss">
.categories-mission {
  .categories-instructions-transition-enter-active,
  .categories-instructions-transition-leave-active {
    transition: opacity 0.3s ease;
    opacity: 1;
  }
  .categories-instructions-transition-enter,
  .categories-instructions-transition-leave-to {
    opacity: 0;
  }
  .cat-card-wrap {
    display: inline-flex;
    max-width: 50%;
    min-width: 20%;
  }
  .cat-card,
  .cat-card-small {
    position: relative;
    font-weight: 500;
    background-color: $color-grey !important;
    width: 100%;
    .number {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .answer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      visibility: hidden;
      background-color: $color-green-light;
      z-index: 3;
    }
    .truncated {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .full {
      position: absolute;
      width: auto;
      white-space: nowrap;
    }
    .hidden {
      opacity: 0;
    }
  }
  .cat-card {
    font-size: 16px;
    line-height: 25px;
    .truncated,
    .full {
      padding: 0 6px;
    }
  }
  .cat-card-small {
    font-size: 12px;
    line-height: 22px;
    .truncated,
    .full {
      padding: 0 5px;
    }
  }
  .cat-active {
    animation-name: show-answer;
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-iteration-count: forwards;
    animation-fill-mode: both;
    .answer {
      visibility: visible;
      transition: visibility 0s linear 0.25s;
      white-space: nowrap;
    }
  }
  @keyframes show-answer {
    0% {
      transform: scaleY(1);
    }
    50% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
  .mission-text-feild {
    position: relative;
    margin-left: 60px;
    margin-right: 6px;
    margin-bottom: 6px;
    transform: translateY(10px);
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease-out;
  }
  .mission-text-feild.show {
    visibility: visible;
    transform: none;
    opacity: 1;
  }
  .text-input {
    pointer-events: none;
    input {
      margin-top: 0 !important;
      caret-color: $color-black !important;
    }
    .v-input__slot {
      margin: 0;
      min-height: auto;
      border-radius: 10px !important;
      border: 2px solid $color-grey-light3 !important;
    }
    .v-text-field__details {
      display: none;
    }
  }
  .text-input.active {
    pointer-events: auto;
  }
  .text-input.wrong ::-webkit-input-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.wrong ::-moz-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.wrong :-moz-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.wrong :-ms-input-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.correct {
    pointer-events: none;
    .v-input__slot {
      border: 2px solid $color-correct !important;
    }
  }
  .text-input-submit {
    padding: 5px;
    font-weight: bold;
    color: $color-grey-light3;
    opacity: 1;
    pointer-events: none;
    cursor: not-allowed;
    transition: color 0.3s;
  }
  .text-input-submit:hover {
    filter: brightness(1.2);
  }
  .text-input-submit.active {
    text-decoration: none;
    color: $primary_accent_color;
    pointer-events: auto;
    cursor: pointer;
  }
  .text-input-submit.correct {
    color: $color-correct;
    pointer-events: none;
    cursor: not-allowed;
  }
  .mission-instructions {
    margin-top: 21px;
    padding: 5px 10px 5px;
  }
}
</style>
